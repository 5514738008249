import { Heading, Progress, Select, SimpleGrid, Stack, useColorModeValue } from '@chakra-ui/react';
import { ErrorBox, NavLinkButton } from 'components/controls';
import { useGetLocationsQuery, useGetSchedulesQuery, useGetTasksQuery, useGetUsersQuery } from 'features/time/timeApi';
import { history } from 'helpers/history';
import React, { useState } from 'react';
import { MdLocationPin, MdManageAccounts, MdSchedule, MdTask } from 'react-icons/md';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import AdminUsersLayout from './users/AdminUsersLayout';

const AdminLayout: React.FC = () => {
    const { data: locations, isLoading: isLoadingLocations, error: errorLocations } = useGetLocationsQuery();
    const { data: schedules, isLoading: isLoadingSchedules, error: errorSchedules } = useGetSchedulesQuery();
    const { data: tasks, isLoading: isLoadingTasks, error: errorTasks } = useGetTasksQuery();
    const { data: users = [], isLoading: isLoadingUsers, error: errorUsers } = useGetUsersQuery();

    const [selectValue, setSelectValue] = useState('');

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        if (value.trim().length !== 0) {
            setSelectValue(value);
            history.navigate && history.navigate(`/admin/${value}`);
        } else {
            setSelectValue('');
            history.navigate && history.navigate('/admin');
        }
    };

    const error = errorLocations || errorSchedules || errorTasks || errorUsers;
    const isLoading = isLoadingLocations || isLoadingSchedules || isLoadingTasks || isLoadingUsers;

    return (
        <Stack spacing={4}>
            <Link to='/admin'>
                <Heading size={{ base: 'md', md: 'lg', lg: 'xl' }} color={useColorModeValue('primary.500', 'primary.200')}>
                    Admin-Bereich
                </Heading>
            </Link>
            <SimpleGrid minChildWidth={150} spacing={4} display={{ base: 'none', md: 'grid' }}>
                <NavLinkButton
                    to='benutzer'
                    w={'100%'}
                    isLoading={isLoadingUsers}
                    isDisabled={!!errorUsers}
                    leftIcon={<MdManageAccounts fontSize={'1.4rem'} />}
                    colorScheme={errorUsers ? 'danger' : 'primary'}
                    onClick={() => setSelectValue('benutzer')}
                >
                    Benutzer
                </NavLinkButton>
                <NavLinkButton
                    to='orte'
                    w={'100%'}
                    isLoading={isLoadingLocations}
                    isDisabled={!!errorLocations}
                    leftIcon={<MdLocationPin fontSize={'1.4rem'} />}
                    colorScheme={errorLocations ? 'danger' : 'primary'}
                    onClick={() => setSelectValue('orte')}
                >
                    Orte
                </NavLinkButton>
                <NavLinkButton
                    to='buchungen'
                    w={'100%'}
                    isLoading={isLoadingSchedules}
                    isDisabled={!!errorSchedules}
                    leftIcon={<MdSchedule fontSize={'1.4rem'} />}
                    colorScheme={errorSchedules ? 'danger' : 'primary'}
                    onClick={() => setSelectValue('buchungen')}
                >
                    Buchungen
                </NavLinkButton>
                <NavLinkButton
                    to='aufgaben'
                    w={'100%'}
                    isLoading={isLoadingTasks}
                    isDisabled={!!errorTasks}
                    leftIcon={<MdTask fontSize={'1.4rem'} />}
                    colorScheme={errorTasks ? 'danger' : 'primary'}
                    onClick={() => setSelectValue('aufgaben')}
                >
                    Aufgaben
                </NavLinkButton>
            </SimpleGrid>
            <Select placeholder='Bereich auswählen' value={selectValue} onChange={handleSelectChange} display={{ base: 'block', md: 'none' }}>
                <option value='benutzer'>Benutzer</option>
                <option value='orte'>Orte</option>
                <option value='buchungen'>Buchungen</option>
                <option value='aufgaben'>Aufgaben</option>
            </Select>
            <Stack spacing={4} border='3px double tomato' borderRadius={'md'}>
                {error ? (
                    <ErrorBox error={error} />
                ) : isLoading ? (
                    <Progress isIndeterminate />
                ) : (
                    <Routes>
                        <Route path='benutzer/*' element={<AdminUsersLayout users={users} />} />
                        <Route path='orte/*' element={<div>Orte</div>} />
                        <Route path='buchungen/*' element={<div>Buchungen</div>} />
                        <Route path='aufgaben/*' element={<div>Aufgaben</div>} />
                        <Route path='*' element={<Navigate to='/admin' />} />
                    </Routes>
                )}
            </Stack>
        </Stack>
    );
};

export default AdminLayout;
