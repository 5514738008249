import { Button } from '@chakra-ui/react';
import { useAppDispatch } from 'app/hooks';
import { alertActions } from 'features/alert/alertSlice';
import React from 'react';

const Spielwiese: React.FC = () => {
    const dispatch = useAppDispatch();

    const foo = {
        name: 'foo',
        test: 'bar',
        help: true,
    };

    const onClick = () => {
        dispatch(alertActions.success({ id: 'toast.1', description: 'Super', title: 'Titel', isClosable: true, duration: 8000 }));
        dispatch(alertActions.warning({ id: 'toast.2', description: 'Toll!' }));
        dispatch(alertActions.info({ id: 'toast.3', description: JSON.stringify(foo, null, 2), type: 'json' }));
        dispatch(alertActions.error({ id: 'toast.4' }));
    };

    return (
        <div>
            <Button onClick={onClick}>Klick</Button>
        </div>
    );
};

export default Spielwiese;
