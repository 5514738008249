import React from 'react';
import { NavLink as NavLinkReact } from 'react-router-dom';

export type CustomNavLinkProps = {
    label: string;
    to: string;
    fontSize?: string;
};

export const CustomNavLink: React.FC<CustomNavLinkProps> = (props) => {
    const { label, to, fontSize = '18px' } = props;
    return (
        <NavLinkReact
            to={to}
            style={({ isActive }) => {
                return {
                    fontSize,
                    fontWeight: isActive ? 700 : 300,
                };
            }}
        >
            {label}
        </NavLinkReact>
    );
};
