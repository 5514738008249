import { Box, SimpleGrid } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { HexColorPicker } from 'react-colorful';

interface IFromColorPickerProps {
    name: string;
    style?: React.CSSProperties;
    useSwatch?: boolean;
}

const presetColors: string[] = [
    '#fc8181',
    '#68d391',
    '#f687b3',
    '#f6e05e',
    '#4fd1c5',
    '#f6ad55',
    '#63b3ed',
    '#b794f4',
    '#e53e3e',
    '#38a169',
    '#d53f8c',
    '#d69e2e',
    '#319795',
    '#dd6b20',
    '#3182ce',
    '#805ad5',
    '#ffffff',
    '#e2e8f0',
    '#cbd5e0',
    '#718096',
    '#4a5568',
    '#2d3748',
    '#1a202c',
    '#000000',
];

export const FormikColorPicker: React.FC<IFromColorPickerProps> = (props) => {
    const { name, style, useSwatch = false } = props;

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <Box bg={'gray.200'} borderRadius={'lg'}>
                    <HexColorPicker color={field.value} onChange={(newColor) => form.setFieldValue(name, newColor)} style={style} />
                    {useSwatch && (
                        <SimpleGrid p={2} columns={8} spacing={2}>
                            {presetColors.map((color, index) => (
                                <Box
                                    key={index}
                                    w='18px'
                                    h={'18px'}
                                    bg={color}
                                    borderRadius={'4px'}
                                    border='1px solid'
                                    borderColor='gray.500'
                                    onClick={() => form.setFieldValue(name, color)}
                                />
                            ))}
                        </SimpleGrid>
                    )}
                </Box>
            )}
        </Field>
    );
};
