import { Box, useColorModeValue } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

export const NavClock: React.FC = () => {
    const clock1 = useColorModeValue('primary.500', 'primary.300');
    const clock2 = useColorModeValue('black', 'white');

    const [secondsDegrees, setSecondsDegrees] = useState<number>(0);
    const [minuteDegrees, setMinuteDegrees] = useState<number>(60);
    const [hourDegrees, setHourDegrees] = useState<number>(90);

    const calculateDegrees = () => {
        const now = new Date();
        const secondsExact = now.getSeconds();
        const minutes = now.getMinutes();
        const minutesExact = minutes + secondsExact / 60;
        const hours = now.getHours();
        const hoursExact = hours + minutesExact / 60;

        setSecondsDegrees(((secondsExact / 60) * 360 + 90) % 360);
        setMinuteDegrees(((minutesExact / 60) * 360 + 90) % 360);
        setHourDegrees(((hoursExact / 12) * 360 + 90) % 360);
    };

    useEffect(() => {
        const interval = setInterval(calculateDegrees, 1000);
        calculateDegrees();
        return () => clearInterval(interval);
    }, []);

    return (
        <Box id='clock' w={'48px'} h={'48px'} border='2px solid' borderColor={clock1} borderRadius={'50%'} mx={'auto'}>
            <Box pos={'relative'} w={'100%'} h={'100%'}>
                <Box
                    id='hand-hour'
                    pos={'absolute'}
                    bg={'primary.500'}
                    height={'2px'}
                    top={'50%'}
                    transformOrigin={'100%'}
                    width={'35%'}
                    ml={'15%'}
                    transform={`translateY(-50%) rotate(${hourDegrees}deg)`}
                />
                <Box
                    id='hand-min'
                    pos={'absolute'}
                    bg={clock2}
                    height={'2px'}
                    top={'50%'}
                    transformOrigin={'100%'}
                    width={'45%'}
                    ml={'5%'}
                    transform={`translateY(-50%) rotate(${minuteDegrees}deg)`}
                />
                <Box
                    id='hand-min'
                    pos={'absolute'}
                    bg={'gray.400'}
                    height={'1px'}
                    top={'50%'}
                    transformOrigin={'100%'}
                    width={'45%'}
                    ml={'5%'}
                    transform={`translateY(-50%) rotate(${secondsDegrees}deg)`}
                />
                <Box
                    id='dot'
                    w={'5px'}
                    h={'5px'}
                    borderRadius={'50%'}
                    bg={clock2}
                    pos={'absolute'}
                    top={'50%'}
                    left={'50%'}
                    transform={'translate(-50%, -50%)'}
                />
            </Box>
        </Box>
    );
};

export const Clock: React.FC = () => {
    const [secondsDegrees, setSecondsDegrees] = useState<number>(0);
    const [minuteDegrees, setMinuteDegrees] = useState<number>(60);
    const [hourDegrees, setHourDegrees] = useState<number>(90);

    const calculateDegrees = () => {
        const now = new Date();
        const secondsExact = now.getSeconds();
        const minutes = now.getMinutes();
        const minutesExact = minutes + secondsExact / 60;
        const hours = now.getHours();
        const hoursExact = hours + minutesExact / 60;

        setSecondsDegrees(((secondsExact / 60) * 360 + 90) % 360);
        setMinuteDegrees(((minutesExact / 60) * 360 + 90) % 360);
        setHourDegrees(((hoursExact / 12) * 360 + 90) % 360);
    };

    useEffect(() => {
        const interval = setInterval(calculateDegrees, 1000);
        calculateDegrees();
        return () => clearInterval(interval);
    }, []);

    return (
        <Box w={'400px'} h={'400px'} border='15px solid' borderColor='primary.500' borderRadius={'50%'} my={'100px'} mx={'auto'}>
            <Box pos={'relative'} w={'100%'} h={'100%'} borderRadius={'50%'} border='3px solid' borderColor={'primary.700'}>
                <Box
                    id='hand-hour'
                    pos={'absolute'}
                    bg={'red.500'}
                    height={'10px'}
                    top={'50%'}
                    borderRadius={'5px'}
                    transformOrigin={'100%'}
                    width={'30%'}
                    ml={'20%'}
                    transform={`translateY(-50%) rotate(${hourDegrees}deg)`}
                />
                <Box
                    id='hand-min'
                    pos={'absolute'}
                    bg={'black'}
                    height={'10px'}
                    top={'50%'}
                    borderRadius={'5px'}
                    transformOrigin={'100%'}
                    width={'45%'}
                    ml={'5%'}
                    transform={`translateY(-50%) rotate(${minuteDegrees}deg)`}
                />
                <Box
                    id='hand-sec'
                    pos={'absolute'}
                    bg={'gray.300'}
                    height={'6px'}
                    top={'50%'}
                    borderRadius={'3px'}
                    transformOrigin={'100%'}
                    width={'45%'}
                    ml={'5%'}
                    transform={`translateY(-50%) rotate(${secondsDegrees}deg)`}
                />
                <Box
                    id='dot'
                    w={'20px'}
                    h={'20px'}
                    borderRadius={'50%'}
                    bg={'black'}
                    pos={'absolute'}
                    top={'50%'}
                    left={'50%'}
                    transform={'translate(-50%, -50%)'}
                />
            </Box>
        </Box>
    );
};
