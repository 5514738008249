import React from 'react';
import { IUser } from 'common/types';
import { Route, Routes } from 'react-router-dom';
import AdminUsersTable from './AdminUsersTable';

interface IProps {
    users: IUser[];
}

const AdminUsersLayout: React.FC<IProps> = (props) => {
    return (
        <Routes>
            <Route index element={<AdminUsersTable users={props.users} />} />
        </Routes>
    );
};

export default AdminUsersLayout;
