import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Checkbox, CheckboxProps, Flex, FormControl, FormErrorMessage, FormLabel, IconButton, InputGroup } from '@chakra-ui/react';
import { Field, FieldProps, FormikErrors, useField, useFormikContext } from 'formik';
import React from 'react';

interface IFormikCheckboxStyled extends CheckboxProps {
    name: string;
    label?: string;
}

export const FormikCheckboxStyled: React.FC<IFormikCheckboxStyled> = (props) => {
    const { name, label, justifyContent, size = 'md' } = props;

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const value = field.value as Boolean;
                return (
                    <FormControl id={name}>
                        {label && <FormLabel>{label}</FormLabel>}
                        <InputGroup display={'flex'} justifyContent={justifyContent}>
                            <IconButton
                                aria-label={`checkbox-${name}`}
                                colorScheme={value ? 'green' : 'red'}
                                icon={value ? <CheckIcon /> : <CloseIcon />}
                                onClick={() => form.setFieldValue(name, !value)}
                                size={size}
                            />
                        </InputGroup>
                    </FormControl>
                );
            }}
        </Field>
    );
};
