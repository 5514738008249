import {
    Button,
    Grid,
    GridItem,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldProps } from 'formik';
import React from 'react';

interface IFormikIconPickerProps {
    name: string;
}

const presetIcons: string[] = [
    'church',
    'skull',
    'house',
    'user',
    'heart',
    'phone',
    'envelope',
    'truck-fast',
    'cloud',
    'face-smile',
    'mug-saucer',
    'fish',
    'car-side',
    'user-secret',
    'clock',
    'pencil',
    'store',
    'desktop',
    'hands',
    'ship',
    'wrench',
];

export const FormikIconPicker: React.FC<IFormikIconPickerProps> = (props) => {
    const { name } = props;

    const textColor = useColorModeValue('white', 'gray.700');
    const bgColor = useColorModeValue('secondary.500', 'secondary.200');

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Field name={name}>
            {({ field: { value }, form }: FieldProps) => (
                <>
                    <Grid templateColumns={'repeat(3, 1fr)'} gap={4}>
                        <GridItem colSpan={2}>
                            <Button w={'100%'} colorScheme='secondary' onClick={onOpen}>
                                Icon auswählen
                            </Button>
                        </GridItem>
                        <GridItem>
                            {typeof value === 'string' && value.length !== 0 && (
                                <IconButton
                                    colorScheme='secondary'
                                    variant={'outline'}
                                    aria-label={`icon-selected-${value}`}
                                    icon={<FontAwesomeIcon icon={['fas', value as IconName]} size='lg' />}
                                    w={'100%'}
                                />
                            )}
                        </GridItem>
                    </Grid>
                    <Modal onClose={onClose} isOpen={isOpen} isCentered>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader bg={bgColor} borderTopRadius={'md'} color={textColor}>
                                Icon auswählen
                            </ModalHeader>
                            <ModalCloseButton color={textColor} />
                            <ModalBody p={4}>
                                <Grid templateColumns={'repeat(7, 1fr)'} gap={4}>
                                    {presetIcons.map((icon, index) => (
                                        <GridItem key={index}>
                                            <IconButton
                                                colorScheme='secondary'
                                                aria-label={`icon-${icon}`}
                                                icon={<FontAwesomeIcon icon={['fas', icon as IconName]} size='lg' />}
                                                onClick={() => {
                                                    onClose();
                                                    form.setFieldValue(name, icon);
                                                }}
                                            />
                                        </GridItem>
                                    ))}
                                </Grid>
                                <Button
                                    mt={4}
                                    colorScheme='danger'
                                    onClick={() => {
                                        onClose();
                                        form.setFieldValue(name, '');
                                    }}
                                >
                                    Löschen
                                </Button>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </Field>
    );
};
