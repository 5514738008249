import { Button, Flex, Stack } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { LoginData, TwoFactorAuthData } from './auth.types';
import { AuthForm } from './AuthForm';
import { FormikInput, FormikPinInput } from 'components/controls/formik';
import { authActions } from './authSlice';

export const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const { showTwoFactor } = useAppSelector((state) => state.auth);

    const initialValuesLogin: LoginData = { email: '', password: '' };

    const onSubmitLogin = (values: LoginData) => {
        return dispatch(authActions.login(values));
    };

    const initialValuesTwoFactor: TwoFactorAuthData = { twoFactorAuthCode: '' };

    const onSubmitTwoFactor = (values: TwoFactorAuthData, helpers: FormikHelpers<TwoFactorAuthData>) => {
        dispatch(authActions.login2fa(values));
        helpers.resetForm();
        helpers.setSubmitting(false);
    };

    return (
        <AuthForm heading='Anmelden'>
            <Formik initialValues={initialValuesLogin} onSubmit={onSubmitLogin}>
                {({ isSubmitting }) => (
                    <Form>
                        <Stack spacing={4} align={'stretch'} display={!showTwoFactor ? 'flex' : 'none'}>
                            <FormikInput
                                id='email'
                                name='email'
                                type='email'
                                autoComplete='email'
                                placeholder='E-Mail'
                                isDisabled={isSubmitting}
                                isRequired
                            />
                            <FormikInput password name='password' placeholder='Passwort' isDisabled={isSubmitting} isRequired />
                            <Button type={'submit'} isLoading={isSubmitting}>
                                Anmelden
                            </Button>
                            {/* <Stack direction={'row'} spacing={4}>
                                <Button
                                    w={'100%'}
                                    colorScheme='secondary'
                                    onClick={() => setValues({ email: 'admin@diebleumers.de', password: 'Abcd!2345' })}
                                >
                                    Admin
                                </Button>
                                <Button w={'100%'} colorScheme='danger' onClick={() => setValues({ email: '', password: '' })}>
                                    Clear
                                </Button>
                                <Button
                                    w={'100%'}
                                    colorScheme='secondary'
                                    onClick={() => setValues({ email: 'heinrich.bleumer@gmail.com', password: 'Abcd1234' })}
                                >
                                    User
                                </Button>
                            </Stack> */}
                            <Link to='../forgot-password'>
                                <Flex justifyContent={'flex-end'}>
                                    <Button variant={'link'}>Passwort vergessen?</Button>
                                </Flex>
                            </Link>
                        </Stack>
                    </Form>
                )}
            </Formik>
            <Formik initialValues={initialValuesTwoFactor} onSubmit={onSubmitTwoFactor}>
                {({ isSubmitting }) => (
                    <Form>
                        <Stack spacing={4} align={'stretch'} display={showTwoFactor ? 'flex' : 'none'}>
                            <FormikPinInput name='twoFactorAuthCode' size={'lg'} />
                            <Button type={'submit'} isLoading={isSubmitting}>
                                Bestätigen
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </AuthForm>
    );
};
