import { Progress } from '@chakra-ui/react';
import React from 'react';
import { useGetTasksQuery } from '../timeApi';
import { ErrorBox } from 'components/controls';
import { Navigate, Route, Routes } from 'react-router-dom';
import TasksForm from './TasksForm';
import TasksTable from './TasksTable';

const TasksLayout: React.FC = () => {
    const { data: tasks, isLoading, error } = useGetTasksQuery();

    if (error) return <ErrorBox error={error} />;

    if (isLoading) return <Progress isIndeterminate />;

    if (tasks) {
        return (
            <Routes>
                <Route index element={<TasksTable tasks={tasks} />} />
                <Route path='add' element={<TasksForm />} />
                <Route path='edit/:id' element={<TasksForm />} />
                <Route path='*' element={<Navigate to='/locations' />} />
            </Routes>
        );
    }

    return <ErrorBox error='Unbekannter Fehler' />;
};

export default TasksLayout;
