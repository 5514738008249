import { Progress } from '@chakra-ui/react';
import React from 'react';
import { useGetLocationsQuery } from '../timeApi';
import { ErrorBox } from 'components/controls';
import { Navigate, Route, Routes } from 'react-router-dom';
import LocationsForm from './LocationsForm';
import LocationsTable from './LocationsTable';

const LocationsLayout: React.FC = () => {
    const { data: locations, isLoading, error } = useGetLocationsQuery();

    if (error) return <ErrorBox error={error} />;

    if (isLoading) return <Progress isIndeterminate />;

    if (locations) {
        return (
            <Routes>
                <Route index element={<LocationsTable locations={locations} />} />
                <Route path='add' element={<LocationsForm />} />
                <Route path='edit/:id' element={<LocationsForm />} />
                <Route path='*' element={<Navigate to='/locations' />} />
            </Routes>
        );
    }

    return <ErrorBox error='Unbekannter Fehler' />;
};

export default LocationsLayout;
