import { CheckIcon, DeleteIcon, TimeIcon, ViewIcon } from '@chakra-ui/icons';
import { Center, IconButton, Stack, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useAppDispatch } from 'app/hooks';
import { DeleteConfirmation } from 'components/main';
import { alertActions } from 'features/alert/alertSlice';
import { IFile } from 'features/time/time.types';
import { useDeleteFileMutation } from 'features/time/timeApi';
import { useTable } from 'functions';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    files: IFile[];
}

const FilesTable: React.FC<IProps> = (props) => {
    const { files } = props;

    const dispatch = useAppDispatch();

    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();

    const [id, setId] = useState('');
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');

    const showDeleteModal = useCallback(async (id: string, message: string) => {
        setId(id);
        setDeleteMessage(message);
        setDisplayConfirmationModal(true);
    }, []);

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const submitDelete = async (fileId: string) => {
        try {
            const { message } = await deleteFile(fileId).unwrap();
            dispatch(
                alertActions.success({
                    description: message,
                    duration: 2000,
                }),
            );
        } catch (error: any) {
            dispatch(
                alertActions.error({
                    title: 'Datei löschen nicht möglich',
                    description: JSON.stringify(error, null, 2),
                    type: 'json',
                }),
            );
        } finally {
            setDisplayConfirmationModal(false);
        }
    };

    const columnHelper = createColumnHelper<IFile>();

    const columns = useMemo<ColumnDef<IFile, any>[]>(
        () => [
            columnHelper.accessor('isSend', {
                header: 'Gesendet?',
                cell: (info) =>
                    info.getValue<boolean>() ? (
                        <Center>
                            <CheckIcon />
                        </Center>
                    ) : null,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('fileName', {
                header: 'Datei',
                cell: (info) => info.getValue<string>().split('-')[1],
            }),
            columnHelper.accessor('fileName', {
                id: 'creationDate',
                header: 'Erstellt',
                cell: (info) => {
                    const fileName = info.getValue<string>();
                    const dateString = fileName.split('-')[0];
                    const year = parseInt(dateString.slice(0, 4), 10);
                    const month = parseInt(dateString.slice(4, 6), 10) - 1;
                    const day = parseInt(dateString.slice(6, 8), 10);
                    const hour = parseInt(dateString.slice(8, 10), 10);
                    const minute = parseInt(dateString.slice(10, 12), 10);
                    const second = parseInt(dateString.slice(12, 14), 10);
                    const date = new Date(year, month, day, hour, minute, second);
                    return (
                        <Tag size={'md'} variant={'solid'} colorScheme={'primary'}>
                            <TagLeftIcon boxSize={'12px'} as={TimeIcon} />
                            <TagLabel>
                                {date.toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}
                            </TagLabel>
                        </Tag>
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor((row) => row, {
                id: 'actions',
                header: '',
                cell: (info) => {
                    const { id, isSend } = info.getValue<IFile>();
                    let message = 'Möchtest du die Datei wirklich löschen?';
                    return (
                        <Stack direction={'row'} spacing={4} justifyContent={'center'}>
                            <Link to={`view/${id}`}>
                                <IconButton size={'sm'} aria-label='View' variant={'solid'}>
                                    <ViewIcon />
                                </IconButton>
                            </Link>
                            <IconButton
                                size={'sm'}
                                aria-label='Delete'
                                colorScheme='danger'
                                isDisabled={isSend || isDeleting}
                                onClick={() => showDeleteModal(id, message)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        [columnHelper, isDeleting, showDeleteModal],
    );

    const { TblBody, TblContainer, TblHead, TblPagination } = useTable<IFile>(files, columns, true, [], false);

    return (
        <>
            <Stack maxW={'container.md'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                <TblContainer>
                    <TblHead />
                    <TblBody />
                </TblContainer>
                <TblPagination />
            </Stack>
            <DeleteConfirmation
                id={id}
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                message={deleteMessage}
            />
        </>
    );
};

export default FilesTable;
