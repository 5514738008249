import { Button, Container, Grid, GridItem, Heading, Progress, Stack, chakra } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ErrorBox } from 'components/controls';
import { FormikColorPicker, FormikIconPicker, FormikInput } from 'components/controls/formik';
import { alertActions } from 'features/alert/alertSlice';
import { Form, Formik, FormikHelpers } from 'formik';
import { history } from 'helpers/history';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAddTaskMutation, useGetTaskQuery, useUpdateTaskMutation } from '../timeApi';
import { ITaskRequest } from '../time.types';

const TasksForm: React.FC = () => {
    const { id } = useParams();
    const isAddMode = !id;

    const dispatch = useAppDispatch();
    const { user: authUser } = useAppSelector((state) => state.auth);

    const { data: task, isLoading: isLoadingTask, error: errorTask } = useGetTaskQuery(id, { skip: isAddMode });
    const [addTask] = useAddTaskMutation();
    const [updateTask] = useUpdateTaskMutation();

    const initialValues: ITaskRequest = {
        id: task?.id,
        title: task?.title ?? '',
        color: task?.color ?? '#38a169',
        icon: task?.icon ?? '',
        userId: authUser?.id ?? '',
    };

    const validationSchema = Yup.object().shape({});

    const onSubmit = async (valuesTemp: ITaskRequest, helpers: FormikHelpers<ITaskRequest>) => {
        const icon = typeof valuesTemp.icon === 'string' && valuesTemp.icon?.length === 0 ? null : valuesTemp.icon;
        const values = { ...valuesTemp, icon };
        try {
            isAddMode ? await addTask(values).unwrap() : await updateTask({ ...values, id }).unwrap();
            dispatch(alertActions.success({ description: `Tätigkeit konnte erfolgreich ${isAddMode ? 'angelegt' : 'aktualisiert'} werden` }));
            history.navigate && history.navigate('/taetigkeiten');
        } catch (error) {
            dispatch(
                alertActions.error({
                    title: `Tätigkeit konnte nicht ${isAddMode ? 'angelegt' : 'aktualisiert'} werden`,
                    description: JSON.stringify(error, null, 2),
                    type: 'json',
                }),
            );
            console.error(error);
        } finally {
            helpers.setSubmitting(false);
        }
    };

    if (errorTask) return <ErrorBox error={errorTask} />;

    if (isLoadingTask) return <Progress isIndeterminate />;

    if (task || isAddMode) {
        return (
            <Container maxW={'container.sm'} mt={8} variant={'timetable'}>
                <Heading variant={'timetable'} size={{ base: 'md', md: 'lg' }}>
                    Tätigkeit {isAddMode ? 'anlegen' : 'bearbeiten'}
                </Heading>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting, values }) => (
                        <Form>
                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                            <Grid templateColumns='repeat(4, 1fr)' gap={4} p={4}>
                                <GridItem rowSpan={2}>
                                    <chakra.section className='small'>
                                        <FormikColorPicker name='color' useSwatch />
                                    </chakra.section>
                                </GridItem>
                                <GridItem colSpan={3}>
                                    <FormikInput name='title' label='Bezeichnung' isRequired />
                                </GridItem>
                                <GridItem colSpan={3}>
                                    <FormikIconPicker name='icon' />
                                </GridItem>
                                <GridItem colSpan={4}>
                                    <Stack direction={'row'} justifyContent={'flex-end'} spacing={4}>
                                        <Link to='..'>
                                            <Button colorScheme='danger' variant={'outline'} size={{ base: 'sm', md: 'md' }}>
                                                Abbrechen
                                            </Button>
                                        </Link>
                                        <Button type='submit' disabled={isSubmitting}>
                                            Ok
                                        </Button>
                                    </Stack>
                                </GridItem>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Container>
        );
    }

    return <ErrorBox error='Unbekannter Fehler' />;
};

export default TasksForm;
