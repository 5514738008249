import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Role } from 'common/enums';
import { Layout, PrivateRoute } from 'components/main';
import { AdminLayout } from 'features/admin';
import { AuthLayout } from 'features/auth';
import { LocationsLayout, SchedulesLayout, TasksLayout } from 'features/time';
import { history } from 'helpers/history';
import { FilesLayout, Home } from 'pages';
import Spielwiese from 'pages/spielwiese';
import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

library.add(fas);

const App: React.FC = () => {
    // init custom history object to allow navigation from
    // anywhere in the react app (inside or outside components)
    history.location = useLocation();
    history.navigate = useNavigate();

    return (
        <Layout>
            <Routes>
                {/* private */}
                <Route element={<PrivateRoute />}>
                    <Route index element={<Home />} />
                    <Route path='buchungen/*' element={<SchedulesLayout />} />
                    <Route path='dateien/*' element={<FilesLayout />} />
                    <Route path='orte/*' element={<LocationsLayout />} />
                    <Route path='taetigkeiten/*' element={<TasksLayout />} />
                </Route>
                {/* private => ADMIN */}
                <Route element={<PrivateRoute roles={Role.Admin} />}>
                    <Route path='admin/*' element={<AdminLayout />} />
                    <Route path='spielwiese/*' element={<Spielwiese />} />
                </Route>
                {/* public */}
                <Route path='auth/*' element={<AuthLayout />} />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
        </Layout>
    );
};

export default App;
