import { StyleFunctionProps, extendTheme, theme, withDefaultColorScheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { selectTheme } from './select.theme';
import { containerTheme } from './container.theme';
import { headingTheme } from './heading.theme';

export const customTheme = extendTheme(
    {
        colors: {
            primary: theme.colors.teal,
            secondary: theme.colors.yellow,
            danger: theme.colors.red,
        },
        styles: {
            global: (props: StyleFunctionProps) => ({
                'html, body': {
                    color: mode('gray.700', 'white')(props),
                    bg: mode('#f4f5fd', 'gray.800')(props),
                },
            }),
        },
        components: {
            Container: containerTheme,
            Heading: headingTheme,
            Select: selectTheme,
        },
    },
    withDefaultColorScheme({ colorScheme: 'primary' }),
);
