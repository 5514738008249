import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authActions } from '../auth/authSlice';
import { IFile, ILocation, ILocationRequest, ISchedule, IScheduleRequest, ISchedulesFileRequest, ITask, ITaskRequest } from './time.types';
import { IUser } from 'common/types';

const baseUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl,
    credentials: 'include',
});

const baseQueryWithRefresh: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        console.warn('Refresh');
        // try to get a new cookie
        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh',
                method: 'GET',
            },
            api,
            extraOptions,
        );

        if (refreshResult.data) {
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(authActions.logout());
        }
    }

    return result;
};

function providesList<R extends { id: string | number }[], T extends string>(resultsWithIds: R | undefined, tagType: T) {
    return resultsWithIds
        ? [{ type: tagType, id: `${tagType}LIST` }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
        : [{ type: tagType, id: `${tagType}LIST` }];
}

const timeApi = createApi({
    reducerPath: 'time',
    baseQuery: baseQueryWithRefresh,
    tagTypes: ['File', 'Location', 'Schedule', 'Task', 'User'],
    endpoints: (build) => ({
        // Files
        addFile: build.mutation<{ message: string; fileId: string }, FormData>({
            query: (body) => ({
                url: '/files',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'File', id: 'FileLIST' }],
        }),
        getFiles: build.query<IFile[], void>({
            query: () => '/files',
            providesTags: (result) => providesList(result, 'File'),
        }),
        deleteFile: build.mutation<{ message: string }, string>({
            query: (id) => ({
                url: `/files/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [
                { type: 'File', id },
                { type: 'Schedule', id: 'ScheduleLIST' },
            ],
        }),
        // Locations
        addLocation: build.mutation<ILocation, ILocationRequest>({
            query: (body) => ({
                url: '/locations',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Location', id: 'LocationLIST' }],
        }),
        getLocations: build.query<ILocation[], void>({
            query: () => '/locations',
            providesTags: (result) => providesList(result, 'Location'),
        }),
        getLocation: build.query<ILocation, string | undefined>({
            query: (id) => `/locations/${id}`,
            providesTags: (result, error, id) => [{ type: 'Location', id }],
        }),
        updateLocation: build.mutation<ILocation, ILocationRequest>({
            query: ({ id, ...patch }) => ({
                url: `/locations/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Location', id }],
        }),
        deleteLocation: build.mutation<{ message: string }, string>({
            query: (id) => ({
                url: `/locations/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Location', id }],
        }),
        // Schedules
        addSchedule: build.mutation<ISchedule, IScheduleRequest>({
            query: (body) => ({
                url: '/schedules',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Schedule', id: 'ScheduleLIST' }],
        }),
        getSchedules: build.query<ISchedule[], void>({
            query: () => '/schedules',
            providesTags: (result) => providesList(result, 'Schedule'),
        }),
        getSchedule: build.query<ISchedule, string | undefined>({
            query: (id) => `/schedules/${id}`,
            providesTags: (result, error, id) => [{ type: 'Schedule', id }],
        }),
        updateSchedule: build.mutation<ISchedule, IScheduleRequest>({
            query: ({ id, ...patch }) => ({
                url: `/schedules/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Schedule', id }],
        }),
        updateSchedulesFile: build.mutation<{ message: string }, ISchedulesFileRequest>({
            query: (body) => ({
                url: '/schedules/updateFile',
                method: 'PATCH',
                body,
            }),
            invalidatesTags: [{ type: 'Schedule', id: 'ScheduleLIST' }],
        }),
        deleteSchedule: build.mutation<{ message: string }, string>({
            query: (id) => ({
                url: `/schedules/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Schedule', id }],
        }),
        // Tasks
        addTask: build.mutation<ITask, ITaskRequest>({
            query: (body) => ({
                url: '/tasks',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Task', id: 'TaskLIST' }],
        }),
        getTasks: build.query<ITask[], void>({
            query: () => '/tasks',
            providesTags: (result) => providesList(result, 'Task'),
        }),
        getTask: build.query<ITask, string | undefined>({
            query: (id) => `/tasks/${id}`,
            providesTags: (result, error, id) => [{ type: 'Task', id }],
        }),
        updateTask: build.mutation<ITask, ITaskRequest>({
            query: ({ id, ...patch }) => ({
                url: `/tasks/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Task', id }],
        }),
        deleteTask: build.mutation<{ message: string }, string>({
            query: (id) => ({
                url: `/tasks/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Task', id }],
        }),
        // Users
        getUsers: build.query<IUser[], void>({
            query: () => '/users',
            providesTags: (result) => providesList(result, 'User'),
        }),
        deleteUser: build.mutation<{ message: string }, string>({
            query: (id) => ({
                url: `/users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'User', id }],
        }),
    }),
});

export const {
    useAddFileMutation,
    useGetFilesQuery,
    useDeleteFileMutation,
    useAddLocationMutation,
    useGetLocationsQuery,
    useGetLocationQuery,
    useUpdateLocationMutation,
    useDeleteLocationMutation,
    useAddScheduleMutation,
    useGetSchedulesQuery,
    useGetScheduleQuery,
    useUpdateScheduleMutation,
    useUpdateSchedulesFileMutation,
    useDeleteScheduleMutation,
    useAddTaskMutation,
    useGetTasksQuery,
    useGetTaskQuery,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
    useGetUsersQuery,
    useDeleteUserMutation,
} = timeApi;

export default timeApi;
