import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
    InputProps,
    FormControl,
    FormLabel,
    InputGroup,
    Input,
    InputRightElement,
    IconButton,
    FormErrorMessage,
    PinInputProps,
    PinInput,
    PinInputField,
    Stack,
    InputRightAddon,
} from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import React from 'react';

interface IFormikInputDataProps {
    label: string;
    name: string;
    value: string | readonly string[] | number | undefined;
    rightLabel?: React.ReactNode;
}

export const FormikInputData: React.FC<IFormikInputDataProps> = (props) => {
    const { label, name, value, rightLabel } = props;

    return (
        <FormControl id={name}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <Input value={value} readOnly />
                {rightLabel && <InputRightAddon children={rightLabel} />}
            </InputGroup>
        </FormControl>
    );
};

interface IFormikInputProps extends InputProps {
    label?: string;
    name: string;
    password?: boolean;
}

export const FormikInput: React.FunctionComponent<IFormikInputProps> = (props) => {
    const { label, name, type, isDisabled, isRequired, password = false, ...rest } = props;

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const handleShow = () => setShowPassword(!showPassword);

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const error = form.errors[name] && form.touched[name];
                const isInvalid = !!error;
                return (
                    <FormControl id={name} isInvalid={isInvalid} isRequired={isRequired}>
                        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
                        <InputGroup>
                            <Input
                                id={password ? 'password' : undefined}
                                type={password && !showPassword ? 'password' : type}
                                autoComplete={password ? 'current-password' : undefined}
                                isDisabled={isDisabled}
                                {...field}
                                {...rest}
                            />
                            {password && (
                                <InputRightElement>
                                    <IconButton aria-label='show password' onClick={handleShow} variant={'ghost'} isDisabled={isDisabled}>
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </IconButton>
                                </InputRightElement>
                            )}
                        </InputGroup>
                        {error && <FormErrorMessage>{`${error}`}</FormErrorMessage>}
                    </FormControl>
                );
            }}
        </Field>
    );
};

interface IFormikPinInputPropes extends Omit<PinInputProps, 'children'> {
    name: string;
    pinValues?: number;
    spacing?: number;
}

export const FormikPinInput: React.FC<IFormikPinInputPropes> = (props) => {
    const { name, pinValues = 6, spacing = 4, ...rest } = props;
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <FormControl id={name}>
                    <Stack direction={'row'} spacing={spacing} w={'100%'} justifyContent={'center'}>
                        <PinInput id={name} {...field} {...rest} onChange={(val) => form.setFieldValue(field.name, val)}>
                            {[...Array(pinValues)].map((_, i) => (
                                <PinInputField key={i} />
                            ))}
                        </PinInput>
                    </Stack>
                </FormControl>
            )}
        </Field>
    );
};
