import { Progress } from '@chakra-ui/react';
import React from 'react';
import { useGetSchedulesQuery } from '../timeApi';
import { ErrorBox } from 'components/controls';
import { Navigate, Route, Routes } from 'react-router-dom';
import SchedulesForm from './SchedulesForm';
import SchedulesPDF from './SchedulesPDF';
import SchedulesTable from './SchedulesTable';

const SchedulesLayout: React.FC = () => {
    const { data: schedules, isLoading, error } = useGetSchedulesQuery();

    if (error) return <ErrorBox error={error} />;

    if (isLoading) return <Progress isIndeterminate />;

    if (schedules) {
        return (
            <Routes>
                <Route index element={<SchedulesTable schedules={schedules} />} />
                <Route path='add' element={<SchedulesForm />} />
                <Route path='edit/:id' element={<SchedulesForm />} />
                <Route path='pdf' element={<SchedulesPDF />} />
                <Route path='*' element={<Navigate to='/schedules' />} />
            </Routes>
        );
    }

    return <ErrorBox error='Unbekannter Fehler' />;
};

export default SchedulesLayout;
