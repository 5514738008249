import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Center, HStack, IconButton, Stack } from '@chakra-ui/react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useAppDispatch } from 'app/hooks';
import { DeleteConfirmation } from 'components/main';
import { alertActions } from 'features/alert/alertSlice';
import { useTable } from 'functions';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDeleteTaskMutation } from '../timeApi';
import { ITask } from '../time.types';

interface IProps {
    tasks: ITask[];
}

const LocationsTable: React.FC<IProps> = (props) => {
    const { tasks } = props;

    const dispatch = useAppDispatch();

    const [deleteLocation, { isLoading: isDeleting }] = useDeleteTaskMutation();

    const [id, setId] = useState('');
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');

    const showDeleteModal = useCallback(async (id: string, title: string) => {
        setId(id);
        setDeleteMessage(title);
        setDisplayConfirmationModal(true);
    }, []);

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const submitDelete = async (id: string) => {
        try {
            const { message } = await deleteLocation(id).unwrap();
            dispatch(
                alertActions.success({
                    description: message,
                    duration: 2000,
                }),
            );
        } catch (error: any) {
            dispatch(
                alertActions.error({
                    title: 'Tätigkeit löschen nicht möglich',
                    description: JSON.stringify(error, null, 2),
                    type: 'json',
                }),
            );
        } finally {
            setDisplayConfirmationModal(false);
        }
    };

    const columnHelper = createColumnHelper<ITask>();

    const columns = useMemo<ColumnDef<ITask, any>[]>(
        () => [
            columnHelper.accessor((row) => row, {
                id: 'color',
                header: () => <Center>Farbe</Center>,
                cell: (info) => {
                    const { id, color, icon } = info.getValue();
                    const myIcon = icon as IconName;
                    return (
                        <Center>
                            <IconButton
                                aria-label={`color-${id}`}
                                bg={color}
                                icon={myIcon && <FontAwesomeIcon icon={['fas', myIcon]} size='lg' color='white' />}
                            />
                        </Center>
                    );
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor('title', { header: 'Titel' }),
            columnHelper.accessor((row) => row, {
                id: 'actions',
                header: '',
                cell: (info) => {
                    const { id, title } = info.getValue();
                    let message = `Möchtest du die Tätigkeit '${title}' wirklich löschen?`;
                    return (
                        <HStack spacing={4} justifyContent={'center'}>
                            <Link to={`edit/${id}`}>
                                <IconButton size='sm' aria-label='Edit' colorScheme='secondary' variant={'outline'}>
                                    <EditIcon />
                                </IconButton>
                            </Link>
                            <IconButton
                                aria-label='Delete'
                                size='sm'
                                colorScheme='danger'
                                disabled={isDeleting}
                                onClick={() => showDeleteModal(id, message)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </HStack>
                    );
                },
                enableColumnFilter: false,
            }),
        ],
        [columnHelper, isDeleting, showDeleteModal],
    );

    const { TblBody, TblContainer, TblFilter, TblHead, TblPagination } = useTable<ITask>(tasks, columns, true, [], false);

    return (
        <>
            <Stack maxW={'container.md'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                <TblFilter />
                <TblContainer>
                    <colgroup>
                        <col width='10%' />
                        <col width='60%' />
                        <col width='30%' />
                    </colgroup>
                    <TblHead />
                    <TblBody />
                </TblContainer>
                <TblPagination />
            </Stack>
            <DeleteConfirmation
                id={id}
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                message={deleteMessage}
            />
        </>
    );
};

export default LocationsTable;
