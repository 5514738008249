import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './app/store';
import App from './App';
import { customTheme } from 'styles';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter basename={baseUrl}>
                <ChakraProvider theme={customTheme}>
                    <CSSReset />
                    <App />
                </ChakraProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
);
