import { Avatar, Heading, Spacer, chakra, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

interface IProps {
    children: React.ReactElement | React.ReactElement[];
    heading: string;
}

export const AuthForm: React.FC<IProps> = (props) => {
    const { children, heading } = props;
    const bg = useColorModeValue('white', 'gray.700');
    const color = useColorModeValue('primary.500', 'primary.200');

    return (
        <>
            <Avatar bg='primary.500' />
            <Heading color={color}>{heading}</Heading>
            <Spacer />
            <chakra.span width={'100%'} bg={bg} boxShadow={'md'} borderRadius={'md'} p={4}>
                {children}
            </chakra.span>
        </>
    );
};
