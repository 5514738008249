import { chakra, FormControl, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { IOption } from 'common/types';
import { Field, FieldProps } from 'formik';
import React from 'react';

interface IProps extends SelectProps {
    name: string;
    options: IOption[];
    label?: string;
}

export const FormikSelect: React.FC<IProps> = (props) => {
    const { label, name, options, isRequired, ...rest } = props;

    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                const error = form.errors[name];
                const isInvalid = !!error;
                return (
                    <FormControl id={name} isInvalid={isInvalid} isRequired={isRequired}>
                        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
                        <Select {...field} {...rest}>
                            {options.map((option, index) => (
                                <chakra.option key={index} value={option.value}>
                                    {option.description}
                                </chakra.option>
                            ))}
                        </Select>
                    </FormControl>
                );
            }}
        </Field>
    );
};
