import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const timetableHeading = defineStyle(() => {
    return {
        p: 4,
        boxShadow: 'md',
        _light: {
            bg: 'primary.500',
            color: 'white',
        },
        _dark: {
            bg: 'primary.200',
            color: 'gray.700',
        },
    };
});

export const headingTheme = defineStyleConfig({
    variants: { timetable: timetableHeading },
});
