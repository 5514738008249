export interface IAlertState {
    id: string;
    status: AlertStatus | null;
    description?: string;
    title?: string;
    duration?: number | null | undefined;
    isClosable?: boolean;
    type: 'string' | 'json';
}

export interface IAlertPayload {
    id?: string;
    description?: string;
    title?: string;
    duration?: number;
    isClosable?: boolean;
    type?: 'string' | 'json';
}

export function isInstanceOfAlertPayload(object: any): object is IAlertPayload {
    return typeof object === 'object' && 'description' in object;
}

export enum AlertStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    LOADING = 'loading',
}
