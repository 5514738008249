import axios from 'axios';

const instance = axios.create({
    withCredentials: true,
    baseURL: `${process.env.REACT_APP_API_URL}/files`,
    responseType: 'blob',
});

const filesService = {
    getById,
};

async function getById(id: string) {
    return instance.get(id);
}

export default filesService;
