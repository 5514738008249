import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppKey } from 'common/constants';
import { IUser } from 'common/types';
import type { AuthState, ForgotPasswordData, LoginData, TwoFactorAuthData } from './auth.types';
import { IApiError } from 'helpers/errorHandler';
import { alertActions } from 'features/alert/alertSlice';
import authService from './authService';
import { history } from 'helpers/history';

// create slice
const name = 'auth';
const initialState: AuthState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const authSlice = createSlice({ name, initialState, reducers });

// exports
export const authActions = { ...authSlice.actions, ...extraActions };
export default authSlice.reducer;

// implementation
function createInitialState(): AuthState {
    const storedAuthData = localStorage.getItem(AppKey);
    let parsedAuthData: IUser | null = null;
    if (storedAuthData) {
        parsedAuthData = JSON.parse(storedAuthData) as IUser;
    }
    return { user: parsedAuthData, showTwoFactor: false };
}

function createReducers() {
    return {
        clearAuthUser,
        setAuthTwoFactor,
        setAuthUser,
    };

    function clearAuthUser(state: AuthState) {
        return initialState;
    }

    function setAuthTwoFactor(state: AuthState, action: PayloadAction<boolean>) {
        state.showTwoFactor = action.payload;
    }

    function setAuthUser(state: AuthState, action: PayloadAction<IUser>) {
        state.user = action.payload;
    }
}

function createExtraActions() {
    return {
        forgotPassword: forgotPassword(),
        login: login(),
        login2fa: login2fa(),
        logout: logout(),
    };

    function forgotPassword() {
        return createAsyncThunk(`${name}/forgot-password`, async (forgotPasswordData: ForgotPasswordData, { dispatch }) => {
            try {
                const {
                    data: { message },
                } = await authService.forgotPassword(forgotPasswordData);
                dispatch(alertActions.success({ description: message }));

                // get return url from location state or default to home page
                const { from } = history.location?.state || { from: { pathname: '/auth/login' } };

                history.navigate && history.navigate(from);
            } catch (error) {
                const err = error as IApiError;
                dispatch(alertActions.error(err));
                console.error(error);
            }
        });
    }

    function login() {
        return createAsyncThunk(`${name}/login`, async (loginData: LoginData, { dispatch }) => {
            try {
                const { data, status } = await authService.login(loginData);

                if (status === 204) {
                    dispatch(authActions.setAuthTwoFactor(true));
                    return;
                }

                // store user details in local storage to keep user logged in between page refreshes
                localStorage.setItem(AppKey, JSON.stringify(data));
                dispatch(authActions.setAuthUser(data));
                history.navigate && history.navigate('/');
            } catch (error) {
                const err = error as IApiError;
                dispatch(alertActions.error(err));
                console.error(error);
            }
        });
    }

    function login2fa() {
        return createAsyncThunk(`${name}/login2fa`, async (loginData: TwoFactorAuthData, { dispatch }) => {
            try {
                const { data } = await authService.login2fa(loginData);

                // store user details in local storage to keep user logged in between page refreshes
                localStorage.setItem(AppKey, JSON.stringify(data));
                dispatch(authActions.setAuthUser(data));
                history.navigate && history.navigate('/');
            } catch (error) {
                const err = error as IApiError;
                dispatch(alertActions.error(err));
                console.error(error);
            }
        });
    }

    function logout() {
        return createAsyncThunk(`${name}/logout`, async (_: void, { dispatch }) => {
            try {
                await authService.logout();
            } catch {
            } finally {
                localStorage.removeItem(AppKey);

                dispatch(authActions.clearAuthUser());

                history.navigate && history.navigate('/auth/login');
            }
        });
    }
}
