import { CustomNavLinkProps } from './CustomNavLink';

const userLinks: CustomNavLinkProps[] = [
    { label: 'Buchungen', to: 'buchungen' },
    { label: 'Orte', to: 'orte' },
    { label: 'Tätigkeiten', to: 'taetigkeiten' },
    { label: 'Dateien', to: 'dateien' },
];

const adminLinks: CustomNavLinkProps[] = [
    { label: 'Admin', to: 'admin' },
    { label: 'Spielwiese', to: 'spielwiese' },
];

const publicLinks: CustomNavLinkProps[] = [];

export const navLinks = { adminLinks, publicLinks, userLinks };
