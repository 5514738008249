import { Button, Stack } from '@chakra-ui/react';
import { useAppDispatch } from 'app/hooks';
import { FormikInput } from 'components/controls/formik';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuthForm } from './AuthForm';
import { authActions } from './authSlice';
import type { ForgotPasswordData } from './auth.types';

export const ForgotPassword: React.FC = () => {
    const dispatch = useAppDispatch();

    // initial values
    const initialValues: ForgotPasswordData = { email: '' };

    const onSubmit = (values: ForgotPasswordData, helpers: FormikHelpers<ForgotPasswordData>) => {
        dispatch(authActions.forgotPassword(values));
        helpers.setSubmitting(false);
    };

    return (
        <AuthForm heading='Passwort vergessen?'>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <Stack spacing={4}>
                            <FormikInput
                                id='email'
                                name='email'
                                type='email'
                                autoComplete='email'
                                placeholder='E-Mail'
                                isDisabled={isSubmitting}
                                isRequired
                            />
                            <Stack direction={'row'} spacing={4} justifyContent={'flex-end'}>
                                <Link to='/auth/login'>
                                    <Button colorScheme={'danger'} variant={'outline'} minW={120}>
                                        Zurück
                                    </Button>
                                </Link>
                                <Button type='submit' isLoading={isSubmitting} minW={120}>
                                    OK
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </AuthForm>
    );
};
