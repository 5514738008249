import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// reducers
import alertReducer from 'features/alert';
import authReducer from 'features/auth';

// APIs
import timeApi from 'features/time/timeApi';

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        [timeApi.reducerPath]: timeApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(timeApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
