import { useAppSelector } from 'app/hooks';
import { Role } from 'common/enums';
import { history } from 'helpers/history';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IProps {
    roles?: Role | Role[];
}

const PrivateRoute: React.FC<IProps> = (props) => {
    const { roles } = props;
    const { user: authUser } = useAppSelector((state) => state.auth);

    if (!authUser) {
        // not logged in so redirect to register page with the return url
        return <Navigate to='/auth/login' state={{ from: history.location }} />;
    }

    // check if route is restricted by role
    if (roles && roles.indexOf(authUser.role) === -1) {
        // role not authorized so redirect to home page
        return <Navigate to='/' />;
    }

    return <Outlet />;
};

export default PrivateRoute;
