import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Box, Flex, chakra, useDisclosure, useColorMode, useColorModeValue, Collapse, Stack, HStack, IconButton, Text } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useRef } from 'react';
import { MenuToggle } from './MenuToggle';
import { Link } from 'react-router-dom';
import { Role } from 'common/enums';
import { navLinks } from './navLinks';
import { CustomNavLink } from './CustomNavLink';
import { MdLogout } from 'react-icons/md';
import { authActions } from 'features/auth/authSlice';
import { NavClock } from './Clock';

const logoSpacing = 4;

export const Nav: React.FC = () => {
    const { adminLinks, publicLinks, userLinks } = navLinks;
    const { isOpen, onToggle } = useDisclosure();
    const { toggleColorMode } = useColorMode();

    const dispatch = useAppDispatch();

    const { user: authUser } = useAppSelector((state) => state.auth);
    const isAdmin = (authUser && authUser.role === Role.Admin) || false;

    const ColorModeIcon = useColorModeValue(SunIcon, MoonIcon);

    const logout = () => {
        dispatch(authActions.logout());
    };

    return (
        <NavContainer>
            <Flex h={{ base: '48px', md: '64px' }} alignItems={'center'} justifyContent={'space-between'} mx={4}>
                <MenuToggle size={'sm'} aria-label={'Open Menu'} display={{ base: 'flex', md: 'none' }} isOpen={isOpen} onToggle={onToggle} />
                <HStack spacing={logoSpacing} alignItems={'center'} flexGrow={{ md: 1 }}>
                    <Logo />
                    <Box pl={logoSpacing} display={{ base: 'none', md: 'block' }}>
                        <NavClock />
                    </Box>
                    <HStack spacing={4} display={{ base: 'none', md: 'flex' }}>
                        {authUser && !isAdmin && userLinks.map((navLink, index) => <CustomNavLink key={index} {...navLink} />)}
                        {publicLinks.map((navLink, index) => (
                            <CustomNavLink key={index} {...navLink} />
                        ))}
                        {isAdmin && adminLinks.map((navLink, index) => <CustomNavLink key={index} {...navLink} />)}
                    </HStack>
                </HStack>
                <HStack>
                    <IconButton size={{ base: 'sm', md: 'md' }} aria-label='Color Mode' icon={<ColorModeIcon />} onClick={toggleColorMode} />
                    {authUser && (
                        <IconButton size={{ base: 'sm', md: 'md' }} aria-label='Logout' icon={<MdLogout fontSize={'1.25rem'} />} onClick={logout} />
                    )}
                </HStack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav isAdmin={isAdmin} />
            </Collapse>
        </NavContainer>
    );
};

interface INavContainerProps {
    children: React.ReactElement[];
}

const NavContainer: React.FC<INavContainerProps> = (props) => {
    const { children } = props;
    const navRef = useRef<HTMLDivElement | null>(null);
    const dimensions = useSize(navRef);

    return (
        <Box id='navContainer'>
            <chakra.nav ref={navRef} bg={useColorModeValue('white', 'gray.700')} boxShadow={'md'} position={'fixed'} w={'100%'} zIndex={3}>
                {children}
            </chakra.nav>
            <Box h={`${dimensions?.height}px`} />
        </Box>
    );
};

interface IMobileNavProps {
    isAdmin: boolean;
}

const MobileNav = React.forwardRef<HTMLDivElement, IMobileNavProps>((props, ref) => {
    // const { isAdmin } = props;
    return <Stack ref={ref} spacing={4} p={4}></Stack>;
});

const Logo: React.FC = () => (
    <Link to='/'>
        <Flex
            pr={{ base: 0, md: logoSpacing }}
            borderRight={{ base: 'none', md: '6px double' }}
            borderRightColor={useColorModeValue('primary.500!important', 'primary.200!important')}
        >
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={300} color={useColorModeValue('primary.500', 'primary.200')}>
                jbl
            </Text>
            <Text fontSize={{ base: 'xl', md: 'xl' }} fontWeight={700} transform={{ md: 'rotate(-90deg)' }}>
                zeit
            </Text>
        </Flex>
    </Link>
);
