import { Heading, Stack } from '@chakra-ui/react';
import { Clock } from 'components/nav/Clock';
import React from 'react';

const Home: React.FC = () => {
    return (
        <Stack>
            <Heading>HOME</Heading>
            <Clock />
        </Stack>
    );
};

export default Home;
