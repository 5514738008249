import { Button, Container, Grid, GridItem, Heading, Progress, Stack, chakra } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ErrorBox } from 'components/controls';
import { FormikCheckboxStyled, FormikColorPicker, FormikIconPicker, FormikInput } from 'components/controls/formik';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAddLocationMutation, useGetLocationQuery, useUpdateLocationMutation } from '../timeApi';
import { ILocationRequest } from '../time.types';
import { alertActions } from 'features/alert/alertSlice';
import { history } from 'helpers/history';

const LocationsForm: React.FC = () => {
    const { id } = useParams();
    const isAddMode = !id;

    const dispatch = useAppDispatch();
    const { user: authUser } = useAppSelector((state) => state.auth);

    const { data: location, isLoading: isLoadingLocation, error: errorLocation } = useGetLocationQuery(id, { skip: isAddMode });
    const [addLocation] = useAddLocationMutation();
    const [updateLocation] = useUpdateLocationMutation();

    const initialValues: ILocationRequest = {
        id: location?.id,
        title: location?.title ?? '',
        color: location?.color ?? '#3182ce',
        icon: location?.icon ?? '',
        userId: authUser?.id ?? '',
        showCompleteMonth: false,
    };

    const validationSchema = Yup.object().shape({});

    const onSubmit = async (valuesTemp: ILocationRequest, helpers: FormikHelpers<ILocationRequest>) => {
        const icon = typeof valuesTemp.icon === 'string' && valuesTemp.icon?.length === 0 ? null : valuesTemp.icon;
        const values = { ...valuesTemp, icon };
        try {
            isAddMode ? await addLocation(values).unwrap() : await updateLocation({ ...values, id }).unwrap();
            dispatch(alertActions.success({ description: `Ort konnte erfolgreich ${isAddMode ? 'angelegt' : 'aktualisiert'} werden` }));
            history.navigate && history.navigate('/orte');
        } catch (error) {
            dispatch(
                alertActions.error({
                    title: `Ort konnte nicht ${isAddMode ? 'angelegt' : 'aktualisiert'} werden`,
                    description: JSON.stringify(error, null, 2),
                    type: 'json',
                }),
            );
            console.error(error);
        } finally {
            helpers.setSubmitting(false);
        }
    };

    if (errorLocation) return <ErrorBox error={errorLocation} />;

    if (isLoadingLocation) return <Progress isIndeterminate />;

    if (location || isAddMode) {
        return (
            <Container maxW={'container.sm'} mt={8} variant={'timetable'}>
                <Heading variant={'timetable'} size={{ base: 'md', md: 'lg' }}>
                    Ort {isAddMode ? 'anlegen' : 'bearbeiten'}
                </Heading>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting, values }) => (
                        <Form>
                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                            <Grid templateColumns='repeat(4, 1fr)' gap={4} p={4}>
                                <GridItem rowSpan={3}>
                                    <chakra.section className='small'>
                                        <FormikColorPicker name='color' useSwatch />
                                    </chakra.section>
                                </GridItem>
                                <GridItem colSpan={3}>
                                    <FormikInput name='title' label='Bezeichnung' isRequired />
                                </GridItem>
                                <GridItem colSpan={3}>
                                    <FormikIconPicker name='icon' />
                                </GridItem>
                                <GridItem colSpan={3}>
                                    <FormikCheckboxStyled name='showCompleteMonth' label='Ganzen Monat ausgeben?' />
                                </GridItem>
                                <GridItem colSpan={4}>
                                    <Stack direction={'row'} justifyContent={'flex-end'} spacing={4}>
                                        <Link to='..'>
                                            <Button colorScheme='danger' variant={'outline'} size={{ base: 'sm', md: 'md' }}>
                                                Abbrechen
                                            </Button>
                                        </Link>
                                        <Button type='submit' disabled={isSubmitting}>
                                            Ok
                                        </Button>
                                    </Stack>
                                </GridItem>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Container>
        );
    }

    return <ErrorBox error='Unbekannter Fehler' />;
};

export default LocationsForm;
