import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlertState, AlertStatus, IAlertPayload, isInstanceOfAlertPayload } from './alert.types';
import { IApiError } from 'helpers/errorHandler';

// create slice
const name = 'alert';
const initialState: IAlertState[] = createInitialState();
const reducers = createReducers();
const alertSlice = createSlice({ name, initialState, reducers });

// exports
export const alertActions = { ...alertSlice.actions };
export default alertSlice.reducer;

// implementation
function createInitialState(): IAlertState[] {
    return [];
}

function createReducers() {
    return {
        success,
        error,
        warning,
        info,
        reset,
    };

    function success(state: IAlertState[], action: PayloadAction<IAlertPayload>) {
        helper(state, action, AlertStatus.SUCCESS);
    }

    function error(state: IAlertState[], action: PayloadAction<string | IApiError | IAlertPayload>) {
        let newState: IAlertState = { status: AlertStatus.ERROR, id: 'alert-0', type: 'string' };
        if (typeof action.payload === 'string') {
            newState = { ...newState, description: action.payload };
        } else if (isInstanceOfAlertPayload(action.payload)) {
            newState = {
                ...newState,
                title: action.payload.title,
                description: action.payload.description,
                duration: action.payload.duration,
                isClosable: action.payload.isClosable,
                type: action.payload.type ?? 'string',
            };
        } else {
            newState = {
                ...newState,
                description: action.payload.message,
                type: 'json',
            };
        }
        state.push(newState);
    }

    function info(state: IAlertState[], action: PayloadAction<IAlertPayload>) {
        helper(state, action, AlertStatus.INFO);
    }

    function warning(state: IAlertState[], action: PayloadAction<IAlertPayload>) {
        helper(state, action, AlertStatus.WARNING);
    }

    function helper(state: IAlertState[], action: PayloadAction<IAlertPayload>, status: AlertStatus) {
        state.push({
            status,
            id: action.payload.id ?? 'alert-0',
            title: action.payload.title,
            description: action.payload.description,
            duration: action.payload.duration,
            isClosable: action.payload.isClosable,
            type: action.payload.type ?? 'string',
        });

        // state.id = action.payload.id ?? 'alert-0';
        // state.title = action.payload.title;
        // state.description = action.payload.description;
        // state.duration = action.payload.duration ?? 4000;
        // state.isClosable = action.payload.isClosable ?? true;
    }

    function reset() {
        return initialState;
    }
}
