import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

interface ISimpleConfirmationModal {
    isOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
    header: string;
    message: string;
}

export const SimpleConfirmationModal: React.FC<ISimpleConfirmationModal> = (props) => {
    const { header, isOpen, onClose, onConfirm, message } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{header}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Alert status='info'>
                        <AlertIcon />
                        <AlertDescription>{message}</AlertDescription>
                    </Alert>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='gray' mr={3} variant='outline' onClick={onClose}>
                        Abbrechen
                    </Button>
                    <Button colorScheme='blue' onClick={() => onConfirm()}>
                        Ok
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
