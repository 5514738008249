import { Progress } from '@chakra-ui/react';
import { ErrorBox } from 'components/controls';
import { useGetFilesQuery } from 'features/time/timeApi';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FilesCreatePage from './FilesCreatePage';
import FilesDetailPage from './FilesDetailPage';
import FilesTable from './FilesTable';

const FilesLayout: React.FC = () => {
    const { data: files, isLoading, error } = useGetFilesQuery();

    if (isLoading) return <Progress isIndeterminate />;

    if (error) return <ErrorBox error={error} />;

    if (files) {
        return (
            <Routes>
                <Route index element={<FilesTable files={files} />} />
                <Route path='view/:id' element={<FilesDetailPage />} />
                <Route path='create' element={<FilesCreatePage />} />
            </Routes>
        );
    }

    return <ErrorBox error='Unbekannter Fehler' />;
};

export default FilesLayout;
