export interface IApiError {
    message: string;
    statusCode: number;
}

export const InternalError: IApiError = {
    message: 'Unbekannter Fehler',
    statusCode: 500,
};

export const onRejectedResponse = (ex: any): Promise<IApiError> => {
    if (typeof ex !== 'object') {
        return Promise.reject(InternalError);
    }

    if (
        ex.hasOwnProperty('response') &&
        typeof ex.response === 'object' &&
        ex.response.hasOwnProperty('data') &&
        typeof ex.response.data === 'object' &&
        ex.response.data.hasOwnProperty('message') &&
        (typeof ex.response.data.message === 'string' || typeof ex.response.data.message === 'object') &&
        ex.response.data.hasOwnProperty('statusCode') &&
        typeof ex.response.data.statusCode === 'number'
    ) {
        let { error, message, statusCode } = ex.response.data;
        if (typeof message === 'object') {
            message = JSON.stringify(message, null, 2);
        }
        return Promise.reject({ error, message, statusCode });
    }

    return Promise.reject(InternalError);
};
