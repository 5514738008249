import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';

const AuthLayout: React.FC = () => {
    return (
        <Flex flexDirection={'column'} width={'100%'} height={'85vh'} justifyContent='center' alignItems={'center'}>
            <Stack flexDir={'column'} mb='2' justifyContent={'center'} alignItems='center' minW={{ base: '90%', md: '468px' }}>
                <Routes>
                    <Route path='login' element={<Login />} />
                    <Route path='twoFactor' element={<div>Two Factor</div>} />
                    <Route path='forgot-password' element={<ForgotPassword />} />
                    {/* <Route path='reset-password' element={<ResetPassword />} /> */}

                    <Route path='*' element={<Navigate to='login' />} />
                </Routes>
            </Stack>
        </Flex>
    );
};

export default AuthLayout;
