import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const timetableSelect = definePartsStyle({
    field: {
        border: '1px solid',
        borderColor: 'primary.500',
        _hover: {
            borderColor: 'primary.600',
        },
        _dark: {
            borderColor: 'primary.200',
            _hover: {
                borderColor: 'primary.300',
            },
        },
    },
    icon: {
        color: 'primary.500',
        _dark: {
            color: 'primary.200',
        },
    },
});

export const selectTheme = defineMultiStyleConfig({
    variants: { timetable: timetableSelect },
});
