import { CalendarIcon } from '@chakra-ui/icons';
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement, useColorMode } from '@chakra-ui/react';
import de from 'date-fns/locale/de';
import { FormikErrors } from 'formik';
import React, { forwardRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.style.scss';

registerLocale('de', de);

const customDateInput = ({ value, onClick, onChange, placeholderText }: any, ref: any) => {
    return (
        <InputGroup>
            <Input autoComplete='off' value={value} ref={ref} onClick={onClick} onChange={onChange} placeholder={placeholderText} />
            <InputRightElement children={<CalendarIcon fontSize={'sm'} />} />
        </InputGroup>
    );
};

const CustomDateInput = forwardRef(customDateInput);

// const customTimeInput = ({ value, onClick, onChange }: any, ref: any) => (
//     <chakra.input
//         bg={'red.500'}
//         autoComplete='off'
//         value={value}
//         ref={ref}
//         onClick={onClick}
//         onChange={onChange}
//     />
// );

// const CustomTimeInput = forwardRef(customTimeInput);

interface DatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
    name: string;
    label?: string;
    onChange: (newDate: Date | null) => void;
    error?: FormikErrors<Date>;
    isRequired?: boolean;
}

export const FormikDatePicker: React.FC<DatePickerProps> = (props) => {
    const { name, label, error, showPopperArrow = false, isRequired = false, onChange, placeholderText, ...rest } = props;

    let json = JSON.stringify(error);
    let errMsg = json?.replace(/"/g, '');

    const { colorMode } = useColorMode();

    return (
        <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <ReactDatePicker
                id={name}
                onChange={onChange}
                showPopperArrow={showPopperArrow}
                timeInputLabel='Zeit:'
                showTimeInput
                locale='de'
                calendarClassName={colorMode === 'light' ? 'light-theme' : 'dark-theme'}
                dateFormat='EEE, dd. MMMM yyyy HH:mm'
                shouldCloseOnSelect={false}
                customInput={<CustomDateInput placeholderText={placeholderText} />}
                // customTimeInput={<CustomTimeInput />}
                {...rest}
            />
            {!!error && <FormErrorMessage>{errMsg}</FormErrorMessage>}
        </FormControl>
    );
};
