import { Button, Container, Grid, GridItem, Heading, Progress, Stack, Textarea } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ErrorBox } from 'components/controls';
import { Form, Formik, FormikHelpers } from 'formik';
import moment, { Moment } from 'moment';
import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useGetLocationsQuery, useAddScheduleMutation, useGetScheduleQuery, useUpdateScheduleMutation, useGetTasksQuery } from '../timeApi';
import { IScheduleRequest } from '../time.types';
import { today8, today9 } from 'common/constants';
import { FormikDatePicker, FormikInputData, FormikSelect } from 'components/controls/formik';
import { IOption } from 'common/types';
import { history } from 'helpers/history';
import { alertActions } from 'features/alert/alertSlice';

interface IOptions {
    isInitialized: boolean;
    locations: IOption[];
    tasks: IOption[];
}

interface IValues extends IScheduleRequest {
    stayOnForm: boolean;
}

const SchedulesForm: React.FC = () => {
    const { id } = useParams();
    const isAddMode = !id;

    const dispatch = useAppDispatch();
    const { user: authUser } = useAppSelector((state) => state.auth);

    const { data: schedule, isLoading: isLoadingSchedule, error: errorSchedule } = useGetScheduleQuery(id, { skip: isAddMode });
    const { data: locationsData, isLoading: isLoadingLocations, error: errorLocations } = useGetLocationsQuery();
    const { data: tasksData, isLoading: isLoadingTasks, error: errorTasks } = useGetTasksQuery();

    const [addSchedule] = useAddScheduleMutation();
    const [updateSchedule] = useUpdateScheduleMutation();

    const [duration, setDuration] = useState<Moment>(moment('2000-01-01 01:00:00'));

    let options: IOptions = { isInitialized: false, locations: [], tasks: [] };

    options = useMemo(() => {
        let locations: IOption[] = [];
        let tasks: IOption[] = [];

        if (schedule) {
            const t = moment.utc(moment(schedule.timeTo).diff(moment(schedule.timeFrom)));
            setDuration(t);
        }

        if (locationsData) {
            locations = locationsData.map((location) => {
                return { value: location.id, description: location.title };
            });
        }

        if (tasksData) {
            tasks = tasksData.map((task) => {
                return { value: task.id, description: task.title };
            });
        }

        return { isInitialized: true, locations, tasks };
    }, [locationsData, schedule, tasksData]);

    const onTimeChange = (timeFrom: Date | null, timeTo: Date | null) => {
        let duration = moment('2000-01-01');
        if (timeFrom && timeTo) {
            let tStart = moment(timeFrom);
            let tEnd = moment(timeTo);
            let diff = tEnd.diff(tStart);
            duration = moment.utc(diff);
        }
        setDuration(duration);
        return duration;
    };

    const initialValues: IValues = {
        id: schedule?.id,
        timeFrom: schedule?.timeFrom ? new Date(schedule.timeFrom) : today8,
        timeTo: schedule?.timeTo ? new Date(schedule.timeTo) : today9,
        remark: schedule?.remark ?? null,
        locationId: schedule?.location ? (typeof schedule.location === 'string' ? schedule.location : schedule.location.id) : '',
        taskId: schedule?.task ? (typeof schedule.task === 'string' ? schedule.task : schedule.task.id) : '',
        userId: authUser?.id ?? '',
        stayOnForm: false,
    };

    const validationSchema = Yup.object().shape({
        timeFrom: Yup.date().typeError('Das ist kein valides Datum').nullable(),
        timeTo: Yup.date()
            .min(Yup.ref('timeFrom'), 'Ende kann nicht vor Start liegen')
            .typeError('Das ist kein valides Datum')
            .nullable()
            .test('sameDay', 'Start und Ende sollten am selben Tag sein', (val, props) => {
                const timeTo = moment(val);
                const timeFrom = moment(props.parent.timeFrom);
                return timeFrom.isSame(timeTo, 'day');
            }),
    });

    const onSubmit = async (valuesTemp: IValues, helpers: FormikHelpers<IValues>) => {
        let { stayOnForm, ...values } = valuesTemp;
        const remark = typeof values.remark === 'string' && values.remark.length === 0 ? null : values.remark;
        values = { ...values, remark };
        try {
            isAddMode ? await addSchedule(values).unwrap() : await updateSchedule({ ...values, id }).unwrap();
            dispatch(alertActions.success({ description: `Buchung konnte erfolgreich ${isAddMode ? 'angelegt' : 'aktualisiert'} werden` }));
            history.navigate && history.navigate('/buchungen');
        } catch (error) {
            dispatch(
                alertActions.error({
                    title: `Buchung konnte nicht ${isAddMode ? 'angelegt' : 'aktualisiert'} werden`,
                    description: JSON.stringify(error, null, 2),
                    type: 'json',
                }),
            );
            console.error(error);
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const error = errorSchedule || errorLocations || errorTasks;
    const isLoading = isLoadingSchedule || isLoadingLocations || isLoadingTasks;

    if (error) return <ErrorBox error={error} />;

    if (isLoading) return <Progress isIndeterminate />;

    if (options.isInitialized) {
        return (
            <Container maxW={'container.sm'} mt={8} variant={'timetable'}>
                <Heading variant={'timetable'} size={{ base: 'md', md: 'lg' }}>
                    Buchung {isAddMode ? 'anlegen' : 'bearbeiten'}
                </Heading>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting, values, handleChange, setFieldValue, errors, touched, setFieldError }) => (
                        <Form>
                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                            <Grid templateColumns='repeat(6, 1fr)' gap={4} p={4}>
                                <GridItem colSpan={{ base: 6, md: 3 }}>
                                    <FormikDatePicker
                                        name='timeFrom'
                                        label='Start'
                                        placeholderText='Arbeitsbeginn'
                                        isRequired
                                        selected={values.timeFrom}
                                        onChange={(date) => {
                                            onTimeChange(date, values.timeTo);
                                            setFieldValue('timeFrom', date);
                                        }}
                                        error={touched.timeFrom && errors.timeFrom}
                                    />
                                </GridItem>
                                <GridItem colSpan={{ base: 6, md: 3 }}>
                                    <FormikDatePicker
                                        name='timeFrom'
                                        label='Ende'
                                        placeholderText='Arbeitsende'
                                        isRequired
                                        selected={values.timeTo}
                                        onChange={(date) => {
                                            onTimeChange(values.timeFrom, date);
                                            setFieldValue('timeTo', date);
                                        }}
                                        error={touched.timeTo && errors.timeTo}
                                    />
                                </GridItem>
                                <GridItem colSpan={{ base: 6, md: 2 }}>
                                    <FormikSelect
                                        isRequired
                                        name='locationId'
                                        label='Arbeitsort'
                                        placeholder='keine Auswahl'
                                        options={options.locations}
                                    />
                                </GridItem>
                                <GridItem colSpan={{ base: 6, md: 2 }}>
                                    <FormikSelect isRequired name='taskId' label='Tätigkeit' placeholder='keine Auswahl' options={options.tasks} />
                                </GridItem>
                                <GridItem colSpan={{ base: 6, md: 2 }}>
                                    <FormikInputData
                                        label='Dauer'
                                        name='duration'
                                        value={duration.hours() > 0 ? duration.format('H:mm') : duration.format('m')}
                                        rightLabel={duration.hours() > 0 ? 'Std.' : 'Min.'}
                                    />
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Textarea
                                        name='remark'
                                        value={values.remark ?? ''}
                                        size={'sm'}
                                        resize={'none'}
                                        onChange={handleChange}
                                        placeholder='Anmerkungen?'
                                    />
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Stack direction={'row'} justifyContent={'flex-end'} spacing={4} mt={4}>
                                        <Link to='..'>
                                            <Button colorScheme='danger' variant={'outline'} size={{ base: 'sm', md: 'md' }}>
                                                Abbrechen
                                            </Button>
                                        </Link>
                                        <Button type='submit' disabled={isSubmitting}>
                                            Ok
                                        </Button>
                                    </Stack>
                                </GridItem>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Container>
        );
    }

    return <ErrorBox error='Unbekannter Fehler' />;
};

export default SchedulesForm;
