import { Box, useColorModeValue } from '@chakra-ui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React from 'react';

interface IErrorBoxProps {
    error: FetchBaseQueryError | SerializedError | string;
}

export const ErrorBox: React.FunctionComponent<IErrorBoxProps> = ({ error }) => (
    <Box
        p={4}
        bg={useColorModeValue('danger.500', 'danger.200')}
        color={useColorModeValue('white', 'gray.700')}
        borderRadius={'lg'}
        border='3px solid'
        borderColor={useColorModeValue('gray.700', 'white')}
    >
        {typeof error === 'string' ? error : <pre>{JSON.stringify(error, null, 2)}</pre>}
    </Box>
);
