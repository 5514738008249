import { Button, ButtonProps } from '@chakra-ui/react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type INavLinkButtonProps = ButtonProps &
    NavLinkProps & {
        children: string;
    };

export const NavLinkButton: React.FC<INavLinkButtonProps> = (props) => {
    const { target, children, leftIcon, isLoading, colorScheme, isDisabled, ...rest } = props;

    return (
        <NavLink {...rest}>
            {({ isActive }) => (
                <Button
                    variant={isActive ? 'solid' : 'outline'}
                    leftIcon={leftIcon}
                    isLoading={isLoading}
                    colorScheme={colorScheme}
                    isDisabled={isDisabled}
                    {...rest}
                >
                    {children}
                </Button>
            )}
        </NavLink>
    );
};
