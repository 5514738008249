import axios from 'axios';
import type { ForgotPasswordData, LoginData, TwoFactorAuthData } from './auth.types';
import { IUser } from 'common/types';
import { onRejectedResponse } from 'helpers/errorHandler';

const instance = axios.create({
    withCredentials: true,
    baseURL: `${process.env.REACT_APP_API_URL}/auth`,
});

const instance2fa = axios.create({
    withCredentials: true,
    baseURL: `${process.env.REACT_APP_API_URL}/2fa`,
});

instance.interceptors.response.use((res) => res, onRejectedResponse);
instance2fa.interceptors.response.use((res) => res, onRejectedResponse);

const authService = {
    forgotPassword,
    login,
    login2fa,
    logout,
};

async function forgotPassword(forgotPasswordData: ForgotPasswordData) {
    return await instance.post<{ message: string }>('/forgot-password', forgotPasswordData);
}

async function login(loginData: LoginData) {
    return await instance.post<IUser>('/login', loginData);
}

async function login2fa(loginData: TwoFactorAuthData) {
    return await instance2fa.post<IUser>('/authenticate', loginData);
}

async function logout() {
    return await instance.post('/logout');
}

export default authService;
