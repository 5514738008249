import { CheckIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Center, HStack, IconButton, Stack } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useAppDispatch } from 'app/hooks';
import { IUser } from 'common/types';
import { DeleteConfirmation } from 'components/main';
import { alertActions } from 'features/alert/alertSlice';
import { useDeleteUserMutation } from 'features/time/timeApi';
import { useTable } from 'functions';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    users: IUser[];
}

const AdminUsersTable: React.FC<IProps> = (props) => (
    <>
        <FormattedTable users={props.users} />
        <FormattedCards users={props.users} />
    </>
);

const FormattedTable: React.FC<IProps> = (props) => {
    const { users } = props;
    const columnHelper = createColumnHelper<IUser>();

    const dispatch = useAppDispatch();

    const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();

    const [id, setId] = useState('');
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');

    const showDeleteModal = useCallback(async (id: string, title: string) => {
        setId(id);
        setDeleteMessage(title);
        setDisplayConfirmationModal(true);
    }, []);

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const submitDelete = async (id: string) => {
        try {
            const { message } = await deleteUser(id).unwrap();
            dispatch(
                alertActions.success({
                    description: message,
                    duration: 2000,
                }),
            );
        } catch (error) {
            dispatch(
                alertActions.error({
                    title: 'User löschen nicht möglich',
                    description: JSON.stringify(error, null, 2),
                    type: 'json',
                }),
            );
            console.error(error);
        } finally {
            setDisplayConfirmationModal(false);
        }
    };

    const columns = useMemo<ColumnDef<IUser, any>[]>(
        () => [
            columnHelper.accessor('isVerified', {
                header: '',
                cell: (info) => {
                    const isVerified = info.getValue() as boolean;
                    return isVerified ? (
                        <Center>
                            <CheckIcon />
                        </Center>
                    ) : null;
                },
                enableColumnFilter: false,
            }),
            columnHelper.accessor((row) => `${row.lastName}, ${row.firstName}`, {
                id: 'fullName',
                header: 'Name',
                enableColumnFilter: false,
            }),
            columnHelper.accessor('userName', { header: 'Benutzername', enableColumnFilter: false }),
            columnHelper.accessor('email', { header: 'E-Mail', enableColumnFilter: false }),
            columnHelper.accessor('role', {
                header: 'Rolle',
            }),
            columnHelper.accessor((row) => row, {
                id: 'actions',
                header: '',
                cell: (info) => {
                    const { id, firstName, lastName } = info.getValue<IUser>();
                    const message = `Möchtest du den Benutzer '${lastName}, ${firstName}' wirklich löschen?`;

                    return (
                        <HStack spacing={4} justifyContent={'center'}>
                            <Link to={`edit/${id}`}>
                                <IconButton size='sm' aria-label='Edit' colorScheme='secondary' variant='outline'>
                                    <EditIcon />
                                </IconButton>
                            </Link>
                            <IconButton
                                aria-label='Delete'
                                size='sm'
                                colorScheme='danger'
                                disabled={isDeleting}
                                onClick={() => showDeleteModal(id, message)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </HStack>
                    );
                },
                enableColumnFilter: false,
            }),
        ],
        [columnHelper, isDeleting, showDeleteModal],
    );

    const { TblBody, TblContainer, TblFilter, TblHead, TblPagination } = useTable(users, columns);

    return (
        <>
            <Stack spacing={4} display={{ base: 'none', md: 'flex' }}>
                <TblFilter />
                <TblContainer>
                    <TblHead />
                    <TblBody />
                </TblContainer>
                <TblPagination />
            </Stack>
            <DeleteConfirmation
                id={id}
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                message={deleteMessage}
            />
        </>
    );
};

const FormattedCards: React.FC<IProps> = (props) => {
    return <Stack spacing={4} display={{ base: 'flex', md: 'none' }} border='3px double' borderColor={'primary.500'}></Stack>;
};

export default AdminUsersTable;
