import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const timetableContainer = defineStyle(() => {
    return {
        p: 0,
        boxShadow: 'md',
        _light: {
            bg: 'white',
        },
        _dark: {
            bg: 'gray.700',
        },
    };
});

export const containerTheme = defineStyleConfig({
    variants: { timetable: timetableContainer },
});
