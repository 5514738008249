import { Box, Container } from '@chakra-ui/react';
import { Alert } from 'features/alert';
import { Nav } from '../nav/Nav';

interface IProps {
    children: React.ReactElement;
}

const Layout: React.FC<IProps> = (props) => {
    const { children } = props;

    return (
        <Box id='app'>
            <Alert />
            <Nav />
            <Container id='content' maxW={'container.2xl'} p={6}>
                {children}
            </Container>
        </Box>
    );
};

export default Layout;
