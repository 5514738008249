import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { Link, useParams } from 'react-router-dom';
import { Button, HStack, Heading, Progress, Stack } from '@chakra-ui/react';
import { ErrorBox } from 'components/controls';
import { IApiError } from 'helpers/errorHandler';
import filesService from './filesService';

const convertBlobToBase64 = (blob: Blob): Promise<any> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

const FilesDetailPage: React.FC = () => {
    const { id } = useParams();

    const [pdfString, setPdfString] = useState<unknown>('');
    const [numPages, setNumPages] = useState(null);
    const [contentDisposition, setContentDisposition] = useState('');
    const [error, setError] = useState<IApiError | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        const fetchAndProcess = async () => {
            setIsLoading(true);

            let response = await filesService.getById(id!);

            const contentType = response.headers['content-type'];
            const blob = new Blob([response.data], { type: contentType });

            let base64String = await convertBlobToBase64(blob);
            setContentDisposition(response.headers['content-disposition']);
            setPdfString(base64String);
        };

        fetchAndProcess()
            .catch((error) => {
                const err = error as IApiError;
                setError(err);
            })
            .finally(() => setIsLoading(false));
    }, [id]);

    if (isLoading) return <Progress isIndeterminate />;

    if (error) return <ErrorBox error={error} />;

    return (
        <Stack w={'100%'} spacing={6}>
            <HStack>
                {contentDisposition && contentDisposition !== '' && (
                    <Heading size={'xl'} flexGrow={1}>
                        {contentDisposition.split('-')[1].split('.')[0].replaceAll('_', ' ')}
                    </Heading>
                )}
                <Link to='/buchungen'>
                    <Button colorScheme='primary'>Buchungen</Button>
                </Link>
                <Link to='/dateien'>
                    <Button colorScheme='primary'>Dateien</Button>
                </Link>
            </HStack>
            <Document file={pdfString} onLoadSuccess={onDocumentLoadSuccess}>
                <Stack direction={'row'} spacing={6} alignItems={'flex-start'}>
                    {Array.apply(null, Array(numPages))
                        .map((_, i) => i + 1)
                        .map((page) => (
                            <Page key={page} pageNumber={page} height={700} />
                        ))}
                </Stack>
            </Document>
        </Stack>
    );
};

export default FilesDetailPage;
