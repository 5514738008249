export const AppKey = 'jbl.dev.timetable';

export const dateFormat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

const today = new Date();

export const today8 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8);
export const today9 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9);
