import { AttachmentIcon, DeleteIcon, EditIcon, InfoIcon } from '@chakra-ui/icons';
import {
    Avatar,
    Box,
    Center,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Tag,
    TagLabel,
    Text,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useAppDispatch } from 'app/hooks';
import { dateFormat } from 'common/constants';
import { alertActions } from 'features/alert/alertSlice';
import { useDeleteScheduleMutation } from 'features/time/timeApi';
import { useTable } from 'functions';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useCallback, useMemo, useState } from 'react';
import { MdOutlinePictureAsPdf } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ILocation, ISchedule, ITask } from '../time.types';
import { DeleteConfirmation } from 'components/main';

const moment = extendMoment(Moment);

interface IProps {
    schedules: ISchedule[];
}

const SchedulesTable: React.FC<IProps> = (props) => {
    const { schedules } = props;
    const locations = schedules.map((schedule) => schedule.location as ILocation);
    const locationsUnique = locations.filter((value, index, self) => index === self.findIndex((t) => t.title === value.title));

    const dispatch = useAppDispatch();

    const [deleteSchedule, { isLoading: isDeleting }] = useDeleteScheduleMutation();

    const [id, setId] = useState('');
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');

    const showDeleteModal = useCallback(async (id: string, title: string) => {
        setId(id);
        setDeleteMessage(title);
        setDisplayConfirmationModal(true);
    }, []);

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const submitDelete = async (id: string) => {
        try {
            const data = await deleteSchedule(id).unwrap();
            dispatch(
                alertActions.success({
                    description: data.message,
                    duration: 2000,
                }),
            );
        } catch (error: any) {
            dispatch(
                alertActions.error({
                    title: 'Buchung löschen nicht möglich',
                    description: JSON.stringify(error, null, 2),
                    type: 'json',
                }),
            );
            console.error(error);
        } finally {
            setDisplayConfirmationModal(false);
        }
    };

    const textColor = useColorModeValue('gray.700', 'white');

    const columnHelper = createColumnHelper<ISchedule>();

    const columns = useMemo<ColumnDef<ISchedule, any>[]>(
        () => [
            columnHelper.accessor('file', {
                id: 'fileBool',
                header: '',
                enableSorting: false,
                cell: (info) => {
                    const value = info.getValue<ISchedule>();
                    const id = value?.id;
                    return (
                        <Center>
                            {id ? (
                                <Link to={`/dateien/view/${id}`}>
                                    <AttachmentIcon color='green.500' fontSize='1.25rem' />
                                </Link>
                            ) : null}
                        </Center>
                    );
                },
                filterFn: (row, columnId, value) => {
                    let rowValue: string = (!!row.getValue(columnId)).toString().toUpperCase();
                    return rowValue === value;
                },
            }),
            columnHelper.accessor('location', {
                header: 'Ort',
                cell: (info) => {
                    const { icon, title, color } = info.getValue<ILocation>();
                    const myIcon = icon as IconName;
                    return (
                        <Tag
                            size={'lg'}
                            w={'100%'}
                            variant={'outline'}
                            fontWeight={'normal'}
                            color={textColor}
                            fontSize={'var(--chakra-fontSizes-sm)'}
                            boxShadow={`1px 1px 0px 2px ${color}`}
                        >
                            {icon && (
                                <Avatar
                                    bg={color!}
                                    color='white'
                                    borderRadius={'md'}
                                    size='xs'
                                    ml={-1}
                                    mr={2}
                                    icon={<FontAwesomeIcon icon={['fas', myIcon]} size='lg' />}
                                />
                            )}
                            <TagLabel>{title}</TagLabel>
                        </Tag>
                    );
                },
                filterFn: (row, columnId, value) => {
                    let rowValue = row.getValue(columnId) as ILocation;
                    return rowValue.title === value;
                },
                sortingFn: (colA, colB, columnId) => {
                    let columnA = colA.getValue(columnId) as ILocation;
                    let columnB = colB.getValue(columnId) as ILocation;
                    return columnA.title.localeCompare(columnB.title);
                },
            }),
            columnHelper.accessor('task', {
                header: 'Tätigkeit',
                cell: (info) => {
                    const { icon, title, color } = info.getValue<ITask>();
                    const myIcon = icon as IconName;
                    return (
                        <Tag
                            size={'lg'}
                            w={'100%'}
                            variant={'outline'}
                            fontWeight={'normal'}
                            color={textColor}
                            fontSize={'var(--chakra-fontSizes-sm)'}
                            boxShadow={`1px 1px 0px 2px ${color}`}
                        >
                            {icon && (
                                <Avatar
                                    bg={color!}
                                    color='white'
                                    borderRadius={'md'}
                                    size='xs'
                                    ml={-1}
                                    mr={2}
                                    icon={<FontAwesomeIcon icon={['fas', myIcon]} size='lg' />}
                                />
                            )}
                            <TagLabel>{title}</TagLabel>
                        </Tag>
                    );
                },
                filterFn: (row, columnId, value) => {
                    let rowValue = row.getValue(columnId) as ITask;
                    return rowValue.title === value;
                },
                sortingFn: (colA, colB, columnId) => {
                    let columnA = colA.getValue(columnId) as ITask;
                    let columnB = colB.getValue(columnId) as ITask;

                    return columnA.title.localeCompare(columnB.title);
                },
            }),
            columnHelper.accessor('remark', {
                header: () => <Center>Info</Center>,
                cell: (info) =>
                    info.getValue() && (
                        <Center>
                            <Tooltip label={info.getValue()}>
                                <InfoIcon fontSize='1.25rem' color='primary.500' />
                            </Tooltip>
                        </Center>
                    ),
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('timeFrom', {
                header: () => <Text textAlign={'center'}>Datum</Text>,
                cell: (info) => <Text textAlign={'center'}>{new Date(info.getValue()).toLocaleDateString('de-DE', dateFormat)}</Text>,
                enableColumnFilter: false,
            }),
            columnHelper.accessor(
                (row) => {
                    const tStart = moment(row.timeFrom).format('HH:mm');
                    const tEnd = moment(row.timeTo).format('HH:mm');
                    return `${tStart} - ${tEnd} Uhr`;
                },
                {
                    id: 'time',
                    header: () => <Text textAlign={'center'}>Zeit</Text>,
                    cell: (info) => <Text textAlign={'center'}>{info.getValue()}</Text>,
                    enableColumnFilter: false,
                    enableSorting: false,
                },
            ),
            columnHelper.accessor(
                (row) => {
                    const tStart = moment(row.timeFrom);
                    const tEnd = moment(row.timeTo);
                    let diff = tEnd.diff(tStart);
                    let duration = moment.utc(diff);
                    let hasHours = duration.hours() > 0;
                    return hasHours ? duration.format('H:mm') + ' Std.' : duration.format('m') + ' Min.';
                },
                {
                    id: 'duration',
                    header: () => <Text textAlign='right'>Dauer</Text>,
                    cell: (info) => <Text textAlign='right'>{info.getValue()}</Text>,
                    enableColumnFilter: false,
                    enableSorting: false,
                },
            ),
            columnHelper.accessor((row) => row, {
                id: 'actions',
                header: '',
                cell: (info) => {
                    const { id, isTransferred, timeFrom, task } = info.getValue<ISchedule>();
                    let message =
                        'Möchtest du ' +
                        (task as ITask).title +
                        ' am ' +
                        new Date(timeFrom).toLocaleDateString('de-DE', dateFormat) +
                        ' wirklich löschen?';
                    return (
                        <HStack spacing={4} justifyContent={'center'}>
                            <Link to={`edit/${id}`}>
                                <IconButton size='sm' aria-label='Edit' colorScheme='secondary' isDisabled={isTransferred || isDeleting}>
                                    <EditIcon />
                                </IconButton>
                            </Link>
                            <IconButton
                                aria-label='Delete'
                                size='sm'
                                colorScheme='danger'
                                isDisabled={isTransferred || isDeleting}
                                onClick={() => showDeleteModal(id, message)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </HStack>
                    );
                },
                enableColumnFilter: false,
            }),
        ],
        [columnHelper, isDeleting, showDeleteModal, textColor],
    );

    const { TblBody, TblContainer, TblFilter, TblHead, TblPagination, getSelectedRows } = useTable<ISchedule>(
        schedules,
        columns,
        true,
        [{ id: 'fileBool', value: 'FALSE' }],
        true,
    );

    const selectedRows: ISchedule[] = getSelectedRows.map((row) => row.original);

    return (
        <>
            <Stack maxW={'1920px'} spacing={4} display={{ base: 'none', lg: 'flex' }}>
                <TblFilter>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='PDF Options'
                            colorScheme='primary'
                            icon={<MdOutlinePictureAsPdf fontSize='1.5rem' />}
                            variant='outline'
                        />
                        <MenuList>
                            {locationsUnique.map((location) => (
                                <Link key={location.id} to='/dateien/create' state={{ rows: schedules, selectedRows, location: location }}>
                                    <MenuItem key={location.id}>
                                        <HStack alignItems='center'>
                                            <Box boxSize='2rem' borderRadius='50%' bg={location?.color ?? ''} />
                                            <span>{location.title}</span>
                                        </HStack>
                                    </MenuItem>
                                </Link>
                            ))}
                        </MenuList>
                    </Menu>
                </TblFilter>
                <TblContainer colorScheme='gray'>
                    <colgroup>
                        <col width='5%' />
                        <col width='5%' />
                        <col width='15%' />
                        <col width='15%' />
                        <col width='5%' />
                        <col width='15%' />
                        <col width='15%' />
                        <col width='15%' />
                        <col width='10%' />
                    </colgroup>
                    <TblHead />
                    <TblBody />
                </TblContainer>
                <TblPagination />
            </Stack>
            <DeleteConfirmation
                id={id}
                showModal={displayConfirmationModal}
                confirmModal={submitDelete}
                hideModal={hideConfirmationModal}
                message={deleteMessage}
            />
        </>
    );
};

export default SchedulesTable;
